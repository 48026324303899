// @flow

import React, { Component } from 'react';
import Layout from '../components/layout';

type Props = {};

export default class AboutPage extends Component<Props> {
  render() {
    return (
      <Layout>
        <h1>About</h1>
        <p>
          A food blog where <a href="https://rosszurowski.com">I</a> review tap
          water from different restaurants around the world. People geek out
          about wine and coffee, so why not water?
        </p>
      </Layout>
    );
  }
}
